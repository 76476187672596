import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Base } from './../../containers/Base/Container';
import { Home } from './../../containers/Home/Container';
import { Walkway } from './../../containers/Walkway/Container';
import { getStyleObject } from './../../containers/utils';
import { BOARD_SIZE } from './../../globalConstants';
import { ContextMenu } from './../../services/contextMenu/Container';

import { getInitialGameData, setPlayers } from './state/actions';
import { BoardEntities } from './state/interfaces';
import {
  basesSelector,
  currentTurnSelector,
  relationshipsSelector,
  walkwaysSelector,
} from './state/selectors';

import styles from './Container.module.css';

interface User {
  id: number;
  name: string;
  value: number;
}

interface IDispatchProps {
  getInitialGameData: typeof getInitialGameData;
  setPlayers: typeof setPlayers;
}

interface IStateProps {
  bases: ReturnType<typeof basesSelector>;
  relationships: ReturnType<typeof relationshipsSelector>;
  walkways: ReturnType<typeof walkwaysSelector>;
  currentTurn: ReturnType<typeof currentTurnSelector>;
}

interface IPublicProps {
  matchedUser: User | null;
  loggedInUser: User;
}

interface IProps extends IPublicProps, IStateProps, IDispatchProps {}

interface IState {
  showPlayerConfiguration: boolean;
}

const mapStateToProps = createStructuredSelector<any, IStateProps>({
  bases: basesSelector,
  currentTurn: currentTurnSelector,
  relationships: relationshipsSelector,
  walkways: walkwaysSelector,
});

const mapDispatchToProps = {
  setPlayers,
  getInitialGameData,
};

class LudoBare extends React.PureComponent<IProps, IState> {
  state: IState = { showPlayerConfiguration: true }

  componentDidMount() {
    this.props.getInitialGameData();
    setTimeout(() => {
      this.startGame(2);
    }, 100);
  }

  render() {
    // const { matchedUser, loggedInUser } = this.props;

    return (
      <div className={styles.Container}>
        <div className={styles.GameContainer}>
          <div className={styles.PlayerContainer}>
            {/* Player components can be configured here */}
          </div>
          <div className={styles.Board} style={getStyleObject(BOARD_SIZE, BOARD_SIZE)}>
            {
              this.renderBoardEntities()
            }
          </div>
          <div className={styles.PlayerContainer}>
            {/* Player components can be configured here */}
          </div>
        </div>
        {
          this.state.showPlayerConfiguration
          ? (
            <div className={styles.GameConfiguration}>
              {/* Game configuration buttons */}
            </div>
          )
          : null
        }
        {
          process.env.NODE_ENV === 'development'
          ? <ContextMenu />
          : null
        }

        {/* Display matchedUser and loggedInUser details */}
        {/* <div className={styles.userInfo}>
          <p>Logged-in User:</p>
          <p>Name: {loggedInUser.name}</p>
          <p>ID: {loggedInUser.id}</p>
          {matchedUser && (
            <>
              <p>Matched User:</p>
              <p>Name: {matchedUser.name}</p>
              <p>ID: {matchedUser.id}</p>
            </>
          )}
        </div> */}
      </div>
    );
  }

  private renderBoardEntities = () => {
    const {
      bases,
      relationships,
      walkways,
      currentTurn,
      matchedUser, 
      loggedInUser
    } = this.props;


    console.log(bases, 'bases');
    console.log(relationships, 'relationships');
    console.log(walkways, 'walkways');
    console.log(currentTurn, 'currentTurn');
    console.log(matchedUser, 'matchedUser');
    console.log(loggedInUser, 'loggedInUser');
    

    return relationships.map((relationship, index) => {
      const base = bases[relationship.ID];
      const walkway = walkways[relationship.ID];
      switch (relationship.type) {
          case BoardEntities.BASE:
          // @ts-ignore
          return <Base 
              matchedUser={matchedUser}
              loggedInUser={loggedInUser}
              // @ts-ignore
              currentTurn={currentTurn}       
              baseID={base.ID} 
              key={index}
              enabled={base.enabled} 
              hasWon={base.hasWon}
            />;
          case BoardEntities.HOME:
          return <Home baseIDs={relationship.baseIDs} key={index}/>;
          case BoardEntities.WALKWAY:
          return <Walkway walkway={walkway!} key={index}/>;
        default:
          return null;
      }
    });
  }

  private startGame = (playerCount: number) => {
    console.log(playerCount, 'playerCount');
    
    this.props.setPlayers(playerCount);
    this.setState({
      showPlayerConfiguration: false,
    });
  }
}
// @ts-ignore
export const Ludo = connect(mapStateToProps, mapDispatchToProps)(LudoBare) as unknown as React.ComponentClass<IPublicProps>;
