import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './style.css';
import Header from '../shared/header';

const Layout: React.FC = (props) => {
    const [hideHeader, setHideHeader] = useState(false);
    const location = useLocation();

    // Define routes where the header should be hidden
    const routesToHide = ['/'];

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        checkRoute();
    }, [location]);
    /* eslint-disable react-hooks/exhaustive-deps */

    const checkRoute = () => {
        setHideHeader(routesToHide.includes(location.pathname));
    };

    return (
        <div className="outer-wrapper">
            {/* Conditional rendering of the header */}
            {!hideHeader && (
                <div className="header">
                    <Header />
                </div>
            )}

            {/* Main outlet where the routed content will be rendered */}
            <div className="main-outlet">
                <main>
                    {/* In React Router v5, we render the children prop instead of Outlet */}
                    {props.children}
                </main>
            </div>
        </div>
    );
};

export default Layout;
