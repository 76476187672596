import React, { FC, useState } from 'react';
import './style.css';
import { useTheme } from '../../hooks/themeContext';




const SearchFilterDropdown: FC = () => {
    const [selectedItem, setSelectedItem] = useState('1'); // Default selected item
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const dropdownItems = ['1', '2', '3', '4', '5', '6'];

    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const handleSelectItem = (item: string) => {
        setSelectedItem(item); 
        setIsDropdownVisible(false); 
    };
    const { theme } = useTheme();
    return (
        <div className="layout-container">
            <form className="dropdown-layout">
                <label htmlFor="countries" className="dropdown-label">
                    Show
                </label>
                <div className="dropdown-container">
                    <label onClick={toggleDropdown} className="dropdown-button">
                        <span className="dropdown-txt" >
                        {selectedItem}
                        </span>
                        {theme === 'light' ? (
                            <img src="/round-history/arrow-down-dark.svg" alt="arrow-down" className="img arrow-theme" />
                        ) : (
                            <img src="/round-history/arrow-down-light.svg" alt="arrow-down" className="img arrow-theme" />
                        )}
                    </label>
                    {isDropdownVisible && (
                        <div id="dropdownMenu" className="dropdown-div" style={{ cursor: 'pointer' }}>
                            {dropdownItems.map((item) => (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                               <a key={item} className="dropdown-option"  onClick={() => handleSelectItem(item)}       >
                                    {item}
                                </a>
                            ))}
                        </div>
                    )}
                </div>
                <label htmlFor="countries" className="Txt">
                    entries
                </label>
            </form>
            <div className="search-filter-container">
                <div className="search-img-container">
                    <input type="text" placeholder="Search" className="search-input" />
                    {theme === 'light' ? (
                        <img src="/round-history/search-dark.svg" alt="search-icon" className="search-img-style" />
                    ) : (
                        <img src="/round-history/search-light.svg" alt="search-icon" className="search-img-style" />
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchFilterDropdown;
