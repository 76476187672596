import React, { FC } from 'react';
import './style.css';
import { useHistory } from 'react-router';

const Dashboard: FC = () => {
    const history = useHistory();

    const startGameHandler = () => {
        history.push('/play-game');
    };

    return (
        <section style={{ paddingLeft: '15px', paddingRight: '15px' }}>
            {/* Next Game Soon button */}
            <div className="next-game-btn">Next Game Soon</div>

            {/* Head section */}
            <div className="head-main">
                <div className="head-first">
                    <h2 className="head-h2">
                        Welcome <br />
                        To Crazy Ludo
                    </h2>
                </div>
            </div>

            {/* Body with grid */}
            <div className="main-body-grid">
                {/* Grid item 1 */}
                <div className="position-relative hover-poiter" onClick={startGameHandler}>
                    <div className="position-relative">
                        <img src="/home/100.svg" style={{ width: '100%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} alt="img-1" />
                        <div className="body-img"></div>
                        <div className="body-img-head">
                            <div className="body-img-head-content">
                                <img src="/home/user.svg" alt="user-icon" />
                                <span>15628</span>
                            </div>
                        </div>
                    </div>
                    <div className="body-img-bottom">
                        <div>
                            <h6 className="bottom-text-1">Crazy Ludo</h6>
                            <h6 className="bottom-text-2">universe</h6>
                        </div>
                        <h6 className="text-[8px] font-[300]">100 Points</h6>
                    </div>
                </div>

                {/* Grid item 2 */}
                <div className="position-relative hover-poiter">
                    <div className="position-relative">
                        <img src="/home/200.svg" style={{ width: '100%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} alt="img-2" />
                        <div className="body-img"></div>
                        <div className="body-img-head">
                            <div className="body-img-head-content">
                                <img src="/home/user.svg" alt="user-icon" />
                                <span>15628</span>
                            </div>
                        </div>
                    </div>
                    <div className="body-img-bottom">
                        <div>
                            <h6 className="bottom-text-1">Crazy Ludo</h6>
                            <h6 className="bottom-text-2">universe</h6>
                        </div>
                        <h6 className="text-[8px] font-[300]">200 Points</h6>
                    </div>
                </div>

                {/* Grid item 3 */}
                <div className="position-relative hover-poiter">
                    <div className="position-relative">
                        <img src="/home/500.svg" style={{ width: '100%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} alt="img-3" />
                        <div className="body-img"></div>
                        <div className="body-img-head">
                            <div className="body-img-head-content">
                                <img src="/home/user.svg" alt="user-icon" />
                                <span>15628</span>
                            </div>
                        </div>
                    </div>
                    <div className="body-img-bottom">
                        <div>
                            <h6 className="bottom-text-1">Crazy Ludo</h6>
                            <h6 className="bottom-text-2">universe</h6>
                        </div>
                        <h6 className="text-[8px] font-[300]">500 Points</h6>
                    </div>
                </div>

                {/* Grid item 4 */}
                <div className="position-relative hover-poiter">
                    <div className="position-relative">
                        <img src="/home/1000.svg" style={{ width: '100%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} alt="img-4" />
                        <div className="body-img"></div>
                        <div className="body-img-head">
                            <div className="body-img-head-content">
                                <img src="/home/user.svg" alt="user-icon" />
                                <span>15628</span>
                            </div>
                        </div>
                    </div>
                    <div className="body-img-bottom">
                        <div>
                            <h6 className="bottom-text-1">Crazy Ludo</h6>
                            <h6 className="bottom-text-2">universe</h6>
                        </div>
                        <h6 className="text-[8px] font-[300]">1000 Points</h6>
                    </div>
                </div>

                {/* Grid item 5 */}
                <div className="position-relative hover-poiter">
                    <div className="position-relative">
                        <img src="/home/2000.svg" style={{ width: '100%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} alt="img-4" />
                        <div className="body-img"></div>
                        <div className="body-img-head">
                            <div className="body-img-head-content">
                                <img src="/home/user.svg" alt="user-icon" />
                                <span>15628</span>
                            </div>
                        </div>
                    </div>
                    <div className="body-img-bottom">
                        <div>
                            <h6 className="bottom-text-1">Crazy Ludo</h6>
                            <h6 className="bottom-text-2">universe</h6>
                        </div>
                        <h6 className="text-[8px] font-[300]">2000 Points</h6>
                    </div>
                </div>

                {/* Grid item 6 */}
                <div className="position-relative hover-poiter">
                    <div className="position-relative">
                        <img src="/home/3000.svg" style={{ width: '100%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} alt="img-4" />
                        <div className="body-img"></div>
                        <div className="body-img-head">
                            <div className="body-img-head-content">
                                <img src="/home/user.svg" alt="user-icon" />
                                <span>15628</span>
                            </div>
                        </div>
                    </div>
                    <div className="body-img-bottom">
                        <div>
                            <h6 className="bottom-text-1">Crazy Ludo</h6>
                            <h6 className="bottom-text-2">universe</h6>
                        </div>
                        <h6 className="text-[8px] font-[300]">3000 Points</h6>
                    </div>
                </div>

                {/* Grid item 7 */}
                <div className="position-relative hover-poiter">
                    <div className="position-relative">
                        <img src="/home/5000.svg" style={{ width: '100%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} alt="img-4" />
                        <div className="body-img"></div>
                        <div className="body-img-head">
                            <div className="body-img-head-content">
                                <img src="/home/user.svg" alt="user-icon" />
                                <span>15628</span>
                            </div>
                        </div>
                    </div>
                    <div className="body-img-bottom">
                        <div>
                            <h6 className="bottom-text-1">Crazy Ludo</h6>
                            <h6 className="bottom-text-2">universe</h6>
                        </div>
                        <h6 className="text-[8px] font-[300]">5000 Points</h6>
                    </div>
                </div>
                {/* Grid item 8 */}
                <div className="position-relative hover-poiter">
                    <div className="position-relative">
                        <img src="/home/10000.svg" style={{ width: '100%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} alt="img-4" />
                        <div className="body-img"></div>
                        <div className="body-img-head">
                            <div className="body-img-head-content">
                                <img src="/home/user.svg" alt="user-icon" />
                                <span>15628</span>
                            </div>
                        </div>
                    </div>
                    <div className="body-img-bottom">
                        <div>
                            <h6 className="bottom-text-1">Crazy Ludo</h6>
                            <h6 className="bottom-text-2">universe</h6>
                        </div>
                        <h6 className="text-[8px] font-[300]">10000 Points</h6>
                    </div>
                </div>
                {/* Grid item 9 */}
                <div className="position-relative hover-poiter">
                    <div className="position-relative">
                        <img src="/home/15000.svg" style={{ width: '100%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} alt="img-4" />
                        <div className="body-img"></div>
                        <div className="body-img-head">
                            <div className="body-img-head-content">
                                <img src="/home/user.svg" alt="user-icon" />
                                <span>15628</span>
                            </div>
                        </div>
                    </div>
                    <div className="body-img-bottom">
                        <div>
                            <h6 className="bottom-text-1">Crazy Ludo</h6>
                            <h6 className="bottom-text-2">universe</h6>
                        </div>
                        <h6 className="text-[8px] font-[300]">15000 Points</h6>
                    </div>
                </div>
                {/* Grid item 10 */}
                <div className="position-relative hover-poiter">
                    <div className="position-relative">
                        <img src="/home/20000.svg" style={{ width: '100%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} alt="img-4" />
                        <div className="body-img"></div>
                        <div className="body-img-head">
                            <div className="body-img-head-content">
                                <img src="/home/user.svg" alt="user-icon" />
                                <span>15628</span>
                            </div>
                        </div>
                    </div>
                    <div className="body-img-bottom">
                        <div>
                            <h6 className="bottom-text-1">Crazy Ludo</h6>
                            <h6 className="bottom-text-2">universe</h6>
                        </div>
                        <h6 className="text-[8px] font-[300]">20000 Points</h6>
                    </div>
                </div>
                {/* Grid item 11 */}
                <div className="position-relative hover-poiter">
                    <div className="position-relative">
                        <img src="/home/25000.svg" style={{ width: '100%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} alt="img-4" />
                        <div className="body-img"></div>
                        <div className="body-img-head">
                            <div className="body-img-head-content">
                                <img src="/home/user.svg" alt="user-icon" />
                                <span>15628</span>
                            </div>
                        </div>
                    </div>
                    <div className="body-img-bottom">
                        <div>
                            <h6 className="bottom-text-1">Crazy Ludo</h6>
                            <h6 className="bottom-text-2">universe</h6>
                        </div>
                        <h6 className="text-[8px] font-[300]">25000 Points</h6>
                    </div>
                </div>
                {/* Grid item 12 */}
                <div className="position-relative hover-poiter">
                    <div className="position-relative">
                        <img src="/home/35000.svg" style={{ width: '100%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} alt="img-4" />
                        <div className="body-img"></div>
                        <div className="body-img-head">
                            <div className="body-img-head-content">
                                <img src="/home/user.svg" alt="user-icon" />
                                <span>15628</span>
                            </div>
                        </div>
                    </div>
                    <div className="body-img-bottom">
                        <div>
                            <h6 className="bottom-text-1">Crazy Ludo</h6>
                            <h6 className="bottom-text-2">universe</h6>
                        </div>
                        <h6 className="text-[8px] font-[300]">35000 Points</h6>
                    </div>
                </div>
                {/* Grid item 13 */}
                <div className="position-relative hover-poiter">
                    <div className="position-relative">
                        <img src="/home/50000.svg" style={{ width: '100%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} alt="img-4" />
                        <div className="body-img"></div>
                        <div className="body-img-head">
                            <div className="body-img-head-content">
                                <img src="/home/user.svg" alt="user-icon" />
                                <span>15628</span>
                            </div>
                        </div>
                    </div>
                    <div className="body-img-bottom">
                        <div>
                            <h6 className="bottom-text-1">Crazy Ludo</h6>
                            <h6 className="bottom-text-2">universe</h6>
                        </div>
                        <h6 className="text-[8px] font-[300]">50000 Points</h6>
                    </div>
                </div>
                {/* Grid item 14 */}
                <div className="position-relative hover-poiter">
                    <div className="position-relative">
                        <img src="/home/100000.svg" style={{ width: '100%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} alt="img-4" />
                        <div className="body-img"></div>
                        <div className="body-img-head">
                            <div className="body-img-head-content">
                                <img src="/home/user.svg" alt="user-icon" />
                                <span>15628</span>
                            </div>
                        </div>
                    </div>
                    <div className="body-img-bottom">
                        <div>
                            <h6 className="bottom-text-1">Crazy Ludo</h6>
                            <h6 className="bottom-text-2">universe</h6>
                        </div>
                        <h6 className="text-[8px] font-[300]">100000 Points</h6>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Dashboard;
