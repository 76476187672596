import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.css";
import React, { Component } from "react";
import Layout from "./Layout";
import RoundHistory from "./pages/roundHistory";
import ProfitLoss from "./pages/profitLoss";
import Dashboard from "./pages/Dashboard";
import { Ludo } from "./containers/Ludo/Container";

class App extends Component {
  render() {
    return (
      <Router>
        <Layout>
          <Switch>
            {/* Redirect from root to dashboard */}
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>

            {/* Define routes */}
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/play-game" component={Ludo} />
            <Route path="/round-history" component={RoundHistory} />
            <Route path="/profit-loss" component={ProfitLoss} />
          </Switch>
        </Layout>
      </Router>
    );
  }
}

export default App;
