import React, { FC, useState } from 'react';
import './style.css';
import Pagination from '../../shared/pagination';
import Searchfilterdropdown from '../../shared/Searchfilterdropdown'; // Assuming you're using CSS for styling

interface TableData {
    id: number;
    amount: number;
    status: string;
    date: string;
    time: string;
}

const ProfitLoss: FC = () => {
    const [activeRowIndex, setActiveRowIndex] = useState<number | null>(null);

    const tableData: TableData[] = [
        { id: 6440419, amount: 280, status: 'green', date: '04-05-2023', time: '06:56 PM' },
        { id: 6440879, amount: 580, status: 'red', date: '04-05-2023', time: '06:56 PM' },
        { id: 6440419, amount: 90, status: 'green', date: '04-05-2023', time: '06:56 PM' },
        { id: 6440419, amount: 60, status: 'red', date: '04-05-2023', time: '06:56 PM' },
        { id: 6440419, amount: 80, status: 'green', date: '04-05-2023', time: '06:56 PM' },
        { id: 6440419, amount: 70, status: 'red', date: '04-05-2023', time: '06:56 PM' },
    ];

    const toggleRow = (index: number) => {
        setActiveRowIndex(activeRowIndex === index ? null : index);
    };

    return (
        <section>
            {/* Title Section */}
            <div className="hero-container profit-loss-img">
                <h2 className="hero-title">Profit Loss</h2>
                <div className="hero-overlay-bg"></div>
            </div>

            {/* Select Dropdown and Search */}
            <Searchfilterdropdown />

            {/* Main Content */}
            <div className="main-wrapper">
                {/* Table Section */}
                <div className="table-section">
                    <table>
                        {/* Heading */}
                        <thead className="text-sm font-semibold">
                            <tr>
                                <th>Game ID</th>
                                <th>Match Amount</th>
                                <th>Win/Loss Amount</th>
                                <th>Date & Time</th>
                            </tr>
                        </thead>
                        {/* Table Content */}
                        <tbody>
                            {tableData.map((row, i) => (
                                <React.Fragment key={i}>
                                    <tr className="table-row">
                                        {/* ID */}
                                        <td className="rounded-custom table-data" onClick={() => toggleRow(i)}>
                                            <div className="id-row">
                                                <img src="/profit-loss/arrow-down.svg" alt="arrow-down" />
                                                {row.id}
                                            </div>
                                        </td>
                                        {/* Amount */}
                                        <td className="table-data">{'$' + row.amount}</td>
                                        {/* Win/Loss Amount */}
                                        <td className={`table-data ${row.status === 'green' ? 'win' : 'loss'}`}>{'$' + row.amount}</td>
                                        {/* Date & Time */}
                                        <td className="rounded-custom-right table-data">
                                            {row.date}
                                            <br />
                                            {row.time}
                                        </td>
                                    </tr>
                                    {/* Expanded Row */}
                                    {activeRowIndex === i && (
                                        <tr className="visible-row">
                                            <td colSpan={4} className="game-details">
                                                <div className="ludo-container">
                                                    <div className="ludo-overlay-bg"></div>
                                                    <img src="/profit-loss/ludo.png" className="ludo-img" alt="ludo" />
                                                </div>
                                                <div className="action-btn-container">
                                                    <button className="action-btns">
                                                        <span>
                                                            <img src="/profit-loss/play.png" alt="play" />
                                                        </span>
                                                    </button>
                                                    <button className="action-btns">
                                                        <span>
                                                            <img src="/profit-loss/pause.png" alt="pause" />
                                                        </span>
                                                    </button>
                                                    <button className="action-btns">
                                                        <span>
                                                            <img src="/profit-loss/ff.png" alt="fast-forward" />
                                                        </span>
                                                    </button>
                                                    <button className="action-btns">
                                                        <span>
                                                            <img src="/profit-loss/fb.png" alt="fast-backward" />
                                                        </span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div style={{ paddingTop: '1.25rem' }}>
                    <Pagination />
                </div>
            </div>
        </section>
    );
};

export default ProfitLoss;
