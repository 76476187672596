import React, { FC } from 'react';
import './style.css';

const Pagination: FC = () => {
    const pages = [1, 2, '...', 5];

    return (
        <div className="pagination-cards-container">
            {/* Previous button */}
            <div className='pagination-bg-color'>
                <img src="/profit-loss/chevron-icon.svg" alt="chevron-nav" style={{ rotate: '180deg' }} />
            </div>

            {/* Loop through pages array */}
            {pages.map((page, index) => (
              <div key={index} className={page === 1 ? 'pagination-border' : ''}>
              <span className="TXT">{page}</span>
          </div>
            ))}

            {/* Next button */}
            <div>
                <img src="/profit-loss/chevron-icon.svg" alt="chevron-nav" className="size-3  " />
            </div>
        </div>
    );
};
export default Pagination;
