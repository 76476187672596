import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { isDieRollValidSelector } from './../../containers/Dice/state/selectors';
import { spawnCoin } from './../../containers/Ludo/state/actions';
import { BaseID, IBase, ICoin } from './../../containers/Ludo/state/interfaces';
import { basesSelector, coinsSelector } from './../../containers/Ludo/state/selectors';
import { getStyleObject } from './../../containers/utils';
import { BASE_SIZE, INNER_BASE_SIZE } from './../../globalConstants';

import { CoinPlaceholder } from './components/CoinPlaceholder';

import styles from './Container.module.css';
import { Player } from './../../containers/Player/Container';

interface User {
  id: number;
  name: string;
  value: number;
}

interface IPublicProps {
  baseID: IBase['ID'];
  enabled: boolean;
  hasWon: boolean;
  matchedUser: User | null;
  loggedInUser: User;
}

interface IStateProps {
  bases: ReturnType<typeof basesSelector>;
  coins: ReturnType<typeof coinsSelector>;
  isDieRollValid: ReturnType<typeof isDieRollValidSelector>;
}

interface IDispatchProps {
  spawnCoin: typeof spawnCoin;
}

interface IProps extends IPublicProps, IDispatchProps, IStateProps {}

const mapDispatchToProps = {
  spawnCoin,
};

const mapStateToProps = createStructuredSelector<any, IStateProps>({
  bases: basesSelector,
  coins: coinsSelector,
  isDieRollValid: isDieRollValidSelector,
});

class BaseBare extends React.PureComponent<IProps> {
  render() {
    // @ts-ignore
    const { baseID, bases, currentTurn, matchedUser, loggedInUser } = this.props;
    const base = bases[baseID];
    const spawnableClass = base.spawnable ? styles.Spawnable : null;

    console.log(matchedUser, 'matchedUser base');
    console.log(loggedInUser, 'loggedInUser base');
    
    return (
      <div className={classnames(styles.OuterContainer, 'ludo-plaers-outer-section')} style={getStyleObject(BASE_SIZE, BASE_SIZE, base.color)}>
        <div className={classnames(styles.InnerContainer, spawnableClass, 'ludo-plaers-section')} style={getStyleObject(INNER_BASE_SIZE, INNER_BASE_SIZE)}>
          {
            base.coinIDs.map((coinID, index) => {
              // box 1
              if (index === 0 && coinID === 'BASE_1_COIN_1') {
                return (
                  <>
                  <div className="justifyandcenter greenplayer">
                    <Player userId={loggedInUser?.id}  baseID={BaseID.BASE_2} placement='top' disabled={currentTurn !== BaseID.BASE_2}/>
                  </div>
                  </>
                );
              }
              if (index === 1 && coinID === 'BASE_1_COIN_2') {
                return null; 
              }
              if (index === 2 && coinID === 'BASE_1_COIN_3') {
                return null; 
              }
              if (index === 3 && coinID === 'BASE_1_COIN_4') {
                return null; 
              }
              // box 4
              if (index === 0 && coinID === 'BASE_4_COIN_1') {
                return (
                  <>
                  <div className="justifyandcenter">
                    <Player userId={matchedUser?.id} baseID={BaseID.BASE_3} placement='bottom' disabled={currentTurn !== BaseID.BASE_3}/>
                    </div>
                  </>
                );
              }
              if (index === 1 && coinID === 'BASE_4_COIN_2') {
                return null; 
              }
              if (index === 2 && coinID === 'BASE_4_COIN_3') {
                return null; 
              }
              if (index === 3 && coinID === 'BASE_4_COIN_4') {
                return null; 
              }

              const coin = this.props.coins[coinID];
              return (
                <CoinPlaceholder
                  key={index}
                  baseColor={base.color}
                  isCoinHidden={!this.props.enabled || (coin.isSpawned || coin.isRetired)}
                  onCoinClicked={() => this.onCoinClicked(base, coin)}
                />
              );
            })
          }
        </div>
      </div>
    );
  }

  private onCoinClicked = (base: IBase, coin: ICoin) => {
    if (base.spawnable && this.props.isDieRollValid) {
      this.props.spawnCoin(base.ID, coin.coinID);
    }
  }
}

export const Base = connect(mapStateToProps, mapDispatchToProps)(BaseBare) as unknown as React.ComponentClass<IPublicProps>;
