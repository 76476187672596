import classnames from "classnames";
import React from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// import { getStyleObject } from "./../../containers/utils";
// import { DICE_SIZE } from "./../../globalConstants";
import { BaseColors } from "./../../state/interfaces";

import { rollDie } from "./state/actions";
import { CONFIGURATIONS } from "./state/constants";
import {
  currentDieRollSelector,
  isDieRollAllowedSelector,
} from "./state/selectors";

import styles from "./Container.module.css";
import "./style.css";

interface IStateProps {
  currentDieRoll: ReturnType<typeof currentDieRollSelector>;
  isDieRollAllowed: ReturnType<typeof isDieRollAllowedSelector>;
}

interface IDispatchProps {
  rollDie: typeof rollDie;
}

interface IPublicProps {
  baseColor: BaseColors;
}

interface IProps extends IStateProps, IDispatchProps, IPublicProps {}

const mapStateToProps = createStructuredSelector<any, IStateProps>({
  currentDieRoll: currentDieRollSelector,
  isDieRollAllowed: isDieRollAllowedSelector,
});

const mapDispatchToProps = {
  rollDie,
};

class DiceBare extends React.PureComponent<IProps> {
  cubeRef: any;

  constructor(props: any) {
    super(props);
    this.state = {
      shouldRenderDots: false, // Control dot rendering after 10 seconds
      currentDiceDot: 0, // Control dot rendering after 10 seconds
    };
    this.cubeRef = React.createRef();
  }

  getRandom = (min: number, max: number) => {
    let random;
    // @ts-ignore
    if (this.state.currentDiceDot === 6) {
      random = 180;
    } else {
      random = 90;
    }
    return Math.floor(Math.random() * (max - min)) + min * random;
  };

  handleCubeClick = () => {
    const cube = this.cubeRef.current;
    const min = 10;
    const max = 10;

    const xRand = this.getRandom(min, max);
    const yRand = this.getRandom(min, max);

    cube.style.transform = `rotateX(${xRand}deg) rotateY(${yRand}deg)`;
    this.props.rollDie();
  };

  render() {
    // @ts-ignore
    const { currentDiceDot } = this.state;
    // const { baseColor, shouldRenderDots,} = this.props;
    const dieClassNames = this.props.isDieRollAllowed
      ? styles.Die
      : [styles.Die, styles.Disabled];

    return (
      <div className={classnames(styles.Container, "dice-animation")}>
        <div style={{ display: "none" }}>{this.renderDots()}</div>
        {/* <section className={classnames("container")}> */}
        <section className={classnames(dieClassNames[1], "container")}>
          <div id="cube" ref={this.cubeRef} onClick={this.handleCubeClick}>
            <div
              className={`front user-front-dice front-dice-${currentDiceDot}`}
            >
              {currentDiceDot === 1 ? (
                <>
                  <span className="dot dot1"></span>
                </>
              ) : null}

              {currentDiceDot === 2 ? (
                <>
                  <span className="dot dot1"></span>
                  <span className="dot dot2"></span>
                </>
              ) : null}

              {currentDiceDot === 3 ? (
                <>
                  <span className="dot dot1"></span>
                  <span className="dot dot2"></span>
                  <span className="dot dot3"></span>
                </>
              ) : null}

              {currentDiceDot === 4 ? (
                <>
                  <span className="dot dot1"></span>
                  <span className="dot dot2"></span>
                  <span className="dot dot3"></span>
                  <span className="dot dot4"></span>
                </>
              ) : null}

              {currentDiceDot === 5 ? (
                <>
                  <span className="dot dot1"></span>
                  <span className="dot dot2"></span>
                  <span className="dot dot3"></span>
                  <span className="dot dot4"></span>
                  <span className="dot dot5"></span>
                </>
              ) : null}

              {currentDiceDot === 6 ? (
                <>
                  <span className="dot dot1"></span>
                  <span className="dot dot2"></span>
                  <span className="dot dot3"></span>
                  <span className="dot dot4"></span>
                  <span className="dot dot5"></span>
                  <span className="dot dot6"></span>
                </>
              ) : null}
            </div>
            <div className="back">
              <span className="dot dot1"></span>
              <span className="dot dot2"></span>
            </div>
            <div className="right">
              <span className="dot dot1"></span>
              <span className="dot dot2"></span>
              <span className="dot dot3"></span>
            </div>
            <div className="left">
              <span className="dot dot1"></span>
              <span className="dot dot2"></span>
              <span className="dot dot3"></span>
              <span className="dot dot4"></span>
            </div>
            <div className="top">
              <span className="dot dot1"></span>
              <span className="dot dot2"></span>
              <span className="dot dot3"></span>
              <span className="dot dot4"></span>
              <span className="dot dot5"></span>
            </div>
            <div className="bottom">
              <span className="dot dot1"></span>
              <span className="dot dot2"></span>
              <span className="dot dot3"></span>
              <span className="dot dot4"></span>
              <span className="dot dot5"></span>
              <span className="dot dot6"></span>
            </div>
          </div>
        </section>
      </div>
    );
  }

  private renderDots = () => {
    const elements: any[] = [];
    const configurationForCurrentRoll =
      CONFIGURATIONS[this.props.currentDieRoll];

    console.log(configurationForCurrentRoll, "configurationForCurrentRoll");

    const countOnes = configurationForCurrentRoll.filter(
      (num) => num === 1
    ).length;
    console.log(countOnes, "countOnes countOnes countOnes"); // Output: 4
    this.setState({ currentDiceDot: countOnes });

    for (let i = 0; i < configurationForCurrentRoll.length; i++) {
      const isVisible = Boolean(configurationForCurrentRoll[i]);
      const classNames = isVisible
        ? styles.Dot
        : [styles.Dot, styles.Invisible];
      elements.push(<div className={classnames(classNames)} key={i} />);
    }

    return elements;
  };
}
// @ts-ignore
export const Dice = connect(
  mapStateToProps,
  mapDispatchToProps
)(DiceBare) as unknown as React.ComponentClass<IPublicProps>;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import classnames from 'classnames';
// import React from 'react';

// import { connect } from 'react-redux';
// import { createStructuredSelector } from 'reselect';

// import { getStyleObject } from './../../containers/utils';
// import { DICE_SIZE } from './../../globalConstants';
// import { BaseColors } from './../../state/interfaces';

// import { rollDie } from './state/actions';
// import { CONFIGURATIONS } from './state/constants';
// import { currentDieRollSelector, isDieRollAllowedSelector } from './state/selectors';

// import styles from './Container.module.css';

// interface IStateProps {
//   currentDieRoll: ReturnType<typeof currentDieRollSelector>;
//   isDieRollAllowed: ReturnType<typeof isDieRollAllowedSelector>;
// }

// interface IDispatchProps {
//   rollDie: typeof rollDie;
// }

// interface IPublicProps {
//   baseColor: BaseColors;
// }

// interface IProps extends IStateProps, IDispatchProps, IPublicProps {}

// const mapStateToProps = createStructuredSelector<any, IStateProps>({
//   currentDieRoll: currentDieRollSelector,
//   isDieRollAllowed: isDieRollAllowedSelector,
// });

// const mapDispatchToProps = {
//   rollDie,
// };

// class DiceBare extends React.PureComponent<IProps> {
//   render() {
//     const { baseColor } = this.props;
//     const dieClassNames = this.props.isDieRollAllowed ? styles.Die : [styles.Die, styles.Disabled];

//     return (
//       <div className={styles.Container}>
//         <div className={classnames(dieClassNames)} style={getStyleObject(DICE_SIZE, DICE_SIZE, baseColor)} onClick={() => this.props.rollDie()}>
//           {
//             this.renderDots()
//           }
//         </div>
//       </div>
//     );
//   }

//   private renderDots = () => {
//     const elements: any[] = [];
//     const configurationForCurrentRoll = CONFIGURATIONS[this.props.currentDieRoll];

//     for (let i = 0; i < configurationForCurrentRoll.length; i++) {
//       const isVisible = Boolean(configurationForCurrentRoll[i]);
//       const classNames = isVisible ? styles.Dot : [styles.Dot, styles.Invisible];
//       elements.push(
//         <div className={classnames(classNames)} key={i}/>,
//       );
//     }

//     return elements;
//   }
// }

// export const Dice = connect(mapStateToProps, mapDispatchToProps)(DiceBare) as unknown as React.ComponentClass<IPublicProps>;
