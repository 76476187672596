import { FC } from 'react';
import './style.css';
import Searchfilterdropdown from '../../shared/Searchfilterdropdown';
import Pagination from '../../shared/pagination';
import React from 'react';

interface HistoryCard {
    titleId: string;
    date: string;
    victoryStatus: string;
    profitAmount: string;
}

const RoundHistory: FC = () => {
    const historyCardsArr: HistoryCard[] = [
        {
            titleId: '644019',
            date: '18 Oct 2023',
            victoryStatus: 'win',
            profitAmount: '89.00',
        },
        {
            titleId: '644019',
            date: '18 Oct 2023',
            victoryStatus: 'loss',
            profitAmount: '67.00',
        },
        {
            titleId: '644019',
            date: '18 Oct 2023',
            victoryStatus: 'win',
            profitAmount: '45.00',
        },
        {
            titleId: '644019',
            date: '18 Oct 2023',
            victoryStatus: 'loss',
            profitAmount: '23.00',
        },
    ];

    return (
        <section className="round-wrapper">
            {/* Hero Image Section */}
            <div className="hero-container round-history-img">
                <h2 className="hero-title">Round History</h2>
                <div className="hero-overlay-bg"></div>
            </div>

            {/* Select Dropdown and Search */}
            <Searchfilterdropdown />

            {/* History Cards Container */}
            <div className="history-cards-container">
                {historyCardsArr.map((item, index) => (
                    <div className="history-card-styles" key={index}>
                        <div className="card-info">
                            <span className="game-id text-white">{item.titleId}</span>
                            <span className="game-date">{item.date}</span>
                        </div>
                        <div className="d-flex flex-column">
                            <span className={`score ${item.victoryStatus !== 'win' ? 'score-loss' : ''}`}>
                                {item.victoryStatus === 'win' ? `+${item.profitAmount}` : `-${item.profitAmount}`}
                            </span>
                            <span className="text-white game-status">{item.victoryStatus} Game</span>
                        </div>
                    </div>
                ))}
            </div>

            {/* Pagination Container */}
            <Pagination />
        </section>
    );
};

export default RoundHistory;
