import React, { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Sidebar from "../sidebar";
import "./style.css";
import { useTheme } from "../../hooks/themeContext";

const Header: FC = () => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [hideIcon, setHideIcon] = useState(false);
  const location = useLocation();
  const { toggleTheme } = useTheme();

  // Define routes where the icon should be hidden
  const routesToHide = ["/round-history", "/profit-loss"];

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    checkRoute();
  }, [location]);
  /* eslint-disable react-hooks/exhaustive-deps */

  const checkRoute = () => {
    setHideIcon(routesToHide.includes(location.pathname));
  };

  const openSidebar = () => {
    setIsOpenSidebar(true);
  };

  const closeSidebar = () => {
    setIsOpenSidebar(false);
  };

  return (
    <>
      <header className="main">
        {/* User info section */}
        {!hideIcon && (
          <div className="d-flex align-items-center left-side-wrapper">
            <img src="/header/dice.svg" alt="dice" />
            <h4>User name</h4>
          </div>
        )}

        {/* Link to home */}
        {hideIcon && (
          <div>
            <Link to="/dashboard">
              <img src="/header/left.svg" alt="left" />
            </Link>
          </div>
        )}

        {/* Balance, theme toggle, and sidebar */}
        <div className="d-flex align-items-center right-side-wrapper">
          <div onClick={toggleTheme}>
            <img src="/header/theme-mode.svg" alt="theme" />
          </div>
          <img src="/header/coin.svg" alt="coin" />
          <div>
            <h6>BAL: 0.51</h6>
            <h6>EXP: 34,051.00</h6>
          </div>
          <div onClick={openSidebar} style={{ cursor: "pointer" }}>
            <img src="/header/sidebar.svg" alt="sidebar" />
          </div>
        </div>

        {/* Sidebar component */}
        {isOpenSidebar && <Sidebar closeSidebar={closeSidebar} />}
      </header>
    </>
  );
};

export default Header;
