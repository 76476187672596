import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

interface SidebarProps {
    closeSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ closeSidebar }) => {
    const [isSidebar, setIsSidebar] = useState(false);

    useEffect(() => {
        // Animate the sidebar opening on mount
        setTimeout(() => {
            setIsSidebar(true);
        }, 0);
    }, []);

    const closeModal = () => {
        setIsSidebar(false);
        // Delay closing sidebar to allow the animation to complete
        setTimeout(() => {
            closeSidebar();
        }, 300);
    };

    const onBackdropClick = (event: React.MouseEvent) => {
        closeModal();
    };

    const stopPropagation = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    return (
        <div className={`main-modal ${isSidebar ? 'opacity-100' : 'opacity-0'}`} onClick={onBackdropClick}>
            {/* Sidebar on the Right */}
            <div className={`sidebar-right ${isSidebar ? 'translate-in' : 'translate-out'} d-flex flex-column`} onClick={stopPropagation}>
                {/* Sidebar Header */}
                <div className="user-name-container">
                    <h6 className="user-name">User name</h6>
                    <img onClick={closeModal} style={{ cursor: 'pointer' }} src="/side-bar/close.svg" alt="close" />
                </div>

                {/* Sidebar Menu */}
                <div style={{ marginTop: '1.5rem' }}>
                    <ul>
                        <li>
                            <Link to="/dashboard" className="menu-item" onClick={closeModal}>
                                <img src="/side-bar/home.png" className="icon" alt="home" />
                                <span className="menu-text">Home</span>
                            </Link>
                        </li>
                        <li className="mt-2">
                            <Link to="/round-history" className="menu-item" onClick={closeModal}>
                                <img src="/side-bar/history.png" className="icon" alt="history" />
                                <span className="menu-text">Round History</span>
                            </Link>
                        </li>
                        <li className="mt-2">
                            <Link to="/profit-loss" className="menu-item" onClick={closeModal}>
                                <img src="/side-bar/profit.png" className="icon" alt="profit" />
                                <span className="menu-text">Profit Loss</span>
                            </Link>
                        </li>
                        <li className="mt-2">
                            <Link to="/login" className="menu-item" onClick={closeModal}>
                                <img src="/side-bar/logout.png" className="icon" alt="logout" />
                                <span className="menu-text">Logout</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
